import "./App.scss";

import { Footer, Header, Resume, Work } from "./container";

import { Navbar } from "./components";
import React from "react";

const App = () => {
	return (
		<div className="app">
			<Navbar />
			<Header />
			<Resume />
			<Work />
			{/* <Skills /> */}
			{/* <Testimonial /> */}
			<Footer />
		</div>
	);
};

export default App;
